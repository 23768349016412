<template>
	<div>
		<div class="header">
			<img class="header-img" src="@assets/osImg/icon/jingbao0.png"
				v-if="tableData.alarm==0" />
			<img class="header-img" src="@assets/osImg/icon/jingbao1.gif"
				v-else-if="tableData.alarm==1" />
			<img class="header-img" src="@assets/osImg/icon/jingbao2.gif"
				v-else-if="tableData.alarm==2" />
			<img class="header-img" src="@assets/osImg/icon/jingbao3.gif"
				v-else-if="tableData.alarm==3" />
			<img class="header-img" src="@assets/osImg/icon/jingbao-1.png"
				v-else-if="tableData.alarm==-1" />
			<div class="header-title" style="vertical-align: middle;">{{ tableData.unitName }}</div>
			<div>{{ tableData.time }}</div>
		</div>
		<div class="body">
			<div class="cedian-item" v-for="item in tableData.meas" :key="item.measId">
				<div class="cedian-item-header">
					<img src="@assets/osImg/jkdp/zhendong0.gif"
						v-if="item.alarm==0" />
					<img src="@assets/osImg/jkdp/zhendong1.gif"
						v-else-if="item.alarm==1" />
					<img src="@assets/osImg/jkdp/zhendong2.gif"
						v-else-if="item.alarm==2" />
					<img src="@assets/osImg/jkdp/zhendong3.gif"
						v-else-if="item.alarm==3" />
					<img src="@assets/osImg/jkdp/zhendong-1.png"
						v-else-if="item.alarm==-1" />
					<span>{{ item.measName }}</span>
				</div>
				<div class="cedian-item-body">
					<div class="cedian-info" @click="getChats(item,'加速度')">
						<div class="cedian-key">加速度</div>
						<div class="cedian-valNum">{{ item.rms_a }} <span>m/s^2</span></div>
						<div class="cedian-status"><img src="@assets/osImg/pointer/ping.png" /></div>
					</div>
					<div class="cedian-info" @click="getChats(item,'速度')">
						<div class="cedian-key">速度</div>
						<div class="cedian-valNum">{{ item.rms_v }}<span>mm/s</span></div>
						<div class="cedian-status"><img src="@assets/osImg/pointer/ping.png" /></div>
					</div>
					<div class="cedian-info" @click="getChats(item,'位移')">
						<div class="cedian-key">位移</div>
						<div class="cedian-valNum">{{ item.rms_s }}<span>um</span></div>
						<div class="cedian-status"><img src="@assets/osImg/pointer/ping.png" /></div>
					</div>
				</div>
			</div>
		</div>
		<chats ref="chats" :row="tbRow" :rows="tbRows"></chats>
	</div>
</template>

<script>
	import chats from '../../monitoring/component/chats.vue';
export default {
	components: {
		chats
	},
	data() {
		return {
			tableData: '',
			from: {},
			tbRow: null,
			tbRows: null,
		};
	},
	created() {
		this.from.time = this.$route.params.time;
		this.from.unitId = this.$route.params.unitId;
		this.getTableList();
	},
	methods: {
		// 获取列表页数据
		getTableList() {
			this.$get('/backend-api/type/alarm/meas-list', this.from).then(res => {
				if (res.code == 1000) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		// 打开图表弹框
		getChats(j,name) {
			this.$refs.chats.dialogVisible = true;
			this.$refs.chats.fullscreen = true;
			j.meas_id = j.measId;
			j.name = name;
			j.type = 1000;
			this.tbRow = j;
			this.tbRows = this.from.unitId;
			// this.fullScreen()
		},
	}
};
</script>

<style scoped>
.header {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 18px;
	padding: 10px;
	background: #ffffff;
	border-bottom: 1px soild #cccccc;
}
.header-img {
	width: 30px;
	height: 30px;
	margin: 0 4px 0 7px;
}
.header-title {
	padding: 0 20px;
}
.body {
	padding: 10px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
}
.cedian-item {
	min-width: 350px;
	background: #ffffff;
	margin:5px 5px 20px 5px;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	cursor: pointer;
}
.cedian-item-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding:10px;
	background: #f3f3f3;
	
}
.cedian-item-header img {
	width: 20px;
	height: 20px;
}
.cedian-item-header span {
	padding-left: 20px;
	font-size: 14px;
}
.cedian-info {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.cedian-info div{
	border-top: 1px solid #d9d9d9;
	padding:0 10px;
	line-height: 38px;
	height: 38px;
}
.cedian-key{
	flex: 1;
	font-size: 14px;
	color: #8c8c8c;
}
.cedian-valNum{
	flex: 1;
}
.cedian-valNum span{
	padding-left: 5px;
	color: #8c8c8c;
}
.cedian-status{
	text-align: center;
	width: 50px;
}
</style>
